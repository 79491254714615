<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    width="60%"
    top="7vh"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="subjectTitle"></span>
    </div>
    <el-row>
      <el-form ref="formData" :model="formData" :rules="rules" label-width="120px" style="width: 98%">
        <el-form-item label="标签：">
          <el-cascader
            v-model="formData.tagSet"
            :options="tagTreeData"
            :props="props"
            placeholder="请选择所属标签"
            clearable></el-cascader>
        </el-form-item>
        <!-- <el-form-item label="题目名称：" prop="title">
          <el-input v-model="formData.title" placeholder="请填写题目名称" maxlength="32"></el-input>
        </el-form-item> -->
        <el-form-item label="题干：" prop="stem">
          <Tinymce v-model="formData.stem" width="100%" :height="350" v-if="show" />
        </el-form-item>

        <!-- 选项最少两个 -->
        <div v-for="(item, index) in formData.itemList" :key="index" class="optionRel">
          <el-form-item
            :label="optionName(index)"
            :prop="'itemList.' + index + '.content'"
            :rules="rules.content"
          >
            <Tinymce v-model="item.content" width="100%" :height="350" v-if="show" />
            <!-- formData.correctAnswer: {{formData.correctAnswer}} -->
            <el-checkbox-group v-model="formData.correctAnswer">
              <!-- <el-checkbox :label="index" :disabled="userTime !== 0">正确答案</el-checkbox> -->
              <el-checkbox :label="index">正确答案</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <template v-if="formData.itemList.length > 2 && userTime === 0">
            <span>
              <i class="el-icon-delete" @click="delOption(item, index)"></i>
            </span>
          </template>
          <!-- <template v-if="index >= 2 && userTime === 0">
            <span>
              <i class="el-icon-delete" @click="delOption(item, index)"></i>
            </span>
          </template> -->
        </div>

        <el-form-item style="text-align: right">
          <el-button
            @click="addOption"
            type="primary"
            size="mini"
            :disabled="userTime !== 0"
          >新增选项</el-button>
        </el-form-item>

        <!-- 解析 -->
        <el-form-item label="解析：">
          <Tinymce v-model="formData.analysis" width="100%" :height="350" v-if="show" />
        </el-form-item>
      </el-form>
    </el-row>

    <el-row slot="footer">
      <el-button type="primary" @click="saveCategory" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import optionName from '@/mixins/getOptionName'
import { questionGetInfo, editQuestion, addQuestion, getQuesUsedTimes, reJudge } from '@/api/research/testquestion'
import getTag from '@/mixins/getTag'

export default {
  components: {
    Tinymce
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editTopicData: {
      default: null,
      type: Object
    },
    ExamDialog: { // 从试卷进来编辑更新试卷信息
      default: false,
      type: Boolean
    }
  },
  mixins: [optionName, getTag],
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('内容不能为空'))
      } else {
        callback()
      }
    }
    return {
      subjectTitle: '',
      props: {
        multiple: true,
        value: 'id',
        label: 'title'
      },
      oldData: {},
      formData: {
        title: '',
        stem: '',
        itemList: [
          {
            content: '',
            correctAnswer: false
          },
          {
            content: '',
            correctAnswer: false
          },
          {
            content: '',
            correctAnswer: false
          },
          {
            content: '',
            correctAnswer: false
          }
        ],
        analysis: '',
        correctAnswer: [] // 正确答案
      },
      rules: {
        title: [{ required: true, message: '请输入题目名称', trigger: 'blur' }],
        stem: [{ required: true, validator: RichTextVal, trigger: 'blur' }],
        content: [{ required: true, validator: RichTextVal, trigger: 'blur' }]
      },
      userTime: 0
    }
  },
  methods: {
    openDialog () {
      this.userTime = 0
      this.formData = {
        title: '',
        stem: '',
        itemList: [
          {
            content: '',
            correctAnswer: false
          },
          {
            content: '',
            correctAnswer: false
          },
          {
            content: '',
            correctAnswer: false
          },
          {
            content: '',
            correctAnswer: false
          }
        ],
        analysis: '',
        correctAnswer: [] // 正确答案
      }
      if (this.editTopicData !== null) {
        this.subjectTitle = '编辑多选题'
        this.questionGetInfo(this.editTopicData.id)
        this.getQuesUsedTimes(this.editTopicData.id)
      } else {
        this.subjectTitle = '新增多选题'
      }
    },

    // 保存
    saveCategory () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          let correctIdx = this.formData.correctAnswer
          this.formData.itemList.forEach((item, index) => {
            this.formData.itemList[index].correctAnswer = false
            correctIdx.forEach(option => {
              if (option === index) {
                this.formData.itemList[index].correctAnswer = true
              }
            })
          })
          if (this.editTopicData !== null) {
            if (!this.formData.tagSet) {
              let editTopicData = {
                id: this.formData.id,
                title: this.formData.title,
                stem: this.formData.stem,
                analysis: this.formData.analysis,
                type: '多选',
                itemList: this.formData.itemList
              }
              editQuestion({
                ...editTopicData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('编辑成功')
                  this.close()
                  if (this.userTime && JSON.stringify(this.formData.itemList) !== JSON.stringify(this.oldData.itemList)) {
                    this.reJudge(this.editTopicData.id)
                  }
                  if (!this.ExamDialog) {
                    this.$emit('getQuestionInfo')
                  } else {
                    this.$emit('getExamInfo')
                  }
                }
              })
            } else {
              const tagSet = []
              const tagSetArr = this.formData.tagSet
              tagSetArr.forEach((ele, idx) => {
                tagSet.push(
                  ele[ele.length-1]
                )
              })
              let editTopicData = {
                id: this.formData.id,
                title: this.formData.title,
                stem: this.formData.stem,
                analysis: this.formData.analysis,
                type: '多选',
                itemList: this.formData.itemList,
                tagSet: tagSet
              }
              editQuestion({
                ...editTopicData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('编辑成功')
                  this.close()
                  if (this.userTime && JSON.stringify(this.formData.itemList) !== JSON.stringify(this.oldData.itemList)) {
                    this.reJudge(this.editTopicData.id)
                  }
                  if (!this.ExamDialog) {
                    this.$emit('getQuestionInfo')
                  } else {
                    this.$emit('getExamInfo')
                  }
                }
              })
            }
          } else {
            if (this.formData.correctAnswer.length === 0) {
              window.$msg('至少选择一个答案', 2)
            } else {
              if (!this.formData.tagSet) {
                let addData = {
                  title: this.formData.title,
                  stem: this.formData.stem,
                  analysis: this.formData.analysis,
                  type: '多选',
                  itemList: this.formData.itemList,
                  status: '已发布'
                }
                addQuestion({
                  ...addData
                }).then(res => {
                  if (res.state === 'success') {
                    window.$msg('添加成功')
                    this.close()
                    this.$emit('getQuestionInfo')
                  }
                })
              } else {
                const tagSet = []
                const tagSetArr = this.formData.tagSet
                tagSetArr.forEach((ele, idx) => {
                  tagSet.push(
                    ele[ele.length-1]
                  )
                })
                let addData = {
                  title: this.formData.title,
                  stem: this.formData.stem,
                  analysis: this.formData.analysis,
                  type: '多选',
                  itemList: this.formData.itemList,
                  tagSet: tagSet,
                  status: '已发布'
                }
                addQuestion({
                  ...addData
                }).then(res => {
                  if (res.state === 'success') {
                    window.$msg('添加成功')
                    this.close()
                    this.$emit('getQuestionInfo')
                  }
                })
              }
            }
          }
        }
      })
    },

    // 获取试题使用次数
    getQuesUsedTimes (questionId) {
      getQuesUsedTimes({
        questionId
      }).then(res => {
        if (res.state === 'success') {
          this.userTime = res.body
        }
      })
    },

    // 重新判题
    reJudge (id) {
      reJudge({
        questionId: id
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('试题重判成功')
        }
      })
    },

    // 获取试题详细信息
    async questionGetInfo (id) {
      const res = await questionGetInfo({
        id
      })
      this.formData = Object.assign({}, res.body)
      let correctAnswer = JSON.parse(res.body.correctAnswer) // 正确选项id —— 数组
      this.formData.correctAnswer = []
      res.body.itemList.forEach((item, index) => {
        correctAnswer.forEach(option => {
          if (item.id === option) {
            this.formData.correctAnswer.push(index)
          }
        })
      })

      const tagGroupOrderList = this.tagGroupList
      const tagList = this.formData.tagList
      const tagSet = []
      tagGroupOrderList.forEach((item, index) => {
        tagList.forEach((ele, idx) => {
          if (item.id === ele.groupId) {
            tagSet.push([
              ele.groupId, ele.id
            ])
          }
        })
      })
      this.formData.tagSet = tagSet

      this.oldData = JSON.parse(JSON.stringify(this.formData))
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 新增选项
    addOption () {
      if (this.formData.itemList[0].content === '正确') {
        this.formData.itemList = []
      }
      if (this.formData.itemList.length >= 10) {
        window.$msg('选项最多十个', 1)
      } else {
        this.formData.itemList.push({
          content: '',
          correctAnswer: false
        })
      }
    },

    // 删除选项
    delOption (item, index) {
      if (this.formData.correctAnswer === index) {
        this.formData.correctAnswer = ''
      }
      this.formData.itemList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.optionRel {
  position: relative;
}
.el-icon-delete {
  position: absolute;
  top: 5px;
  right: -35px;
  cursor: pointer;
  font-size: 16px;
}

.w-e-text-container {
  height: 160px !important;
}

/deep/ .el-dialog {
  min-width: 940px;
}
/deep/ .el-dialog__body {
  padding-right: 90px !important;
}
</style>
