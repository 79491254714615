var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        width: "60%",
        top: "7vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.subjectTitle) } }),
        ]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticStyle: { width: "98%" },
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签：" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.tagTreeData,
                      props: _vm.props,
                      placeholder: "请选择所属标签",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.tagSet,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "tagSet", $$v)
                      },
                      expression: "formData.tagSet",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "题干：", prop: "stem" } },
                [
                  _vm.show
                    ? _c("Tinymce", {
                        attrs: { width: "100%", height: 350 },
                        model: {
                          value: _vm.formData.stem,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "stem", $$v)
                          },
                          expression: "formData.stem",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(_vm.formData.itemList, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "optionRel" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.optionName(index),
                          prop: "itemList." + index + ".content",
                          rules: _vm.rules.content,
                        },
                      },
                      [
                        _vm.show
                          ? _c("Tinymce", {
                              attrs: { width: "100%", height: 350 },
                              model: {
                                value: item.content,
                                callback: function ($$v) {
                                  _vm.$set(item, "content", $$v)
                                },
                                expression: "item.content",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.formData.correctAnswer,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "correctAnswer", $$v)
                              },
                              expression: "formData.correctAnswer",
                            },
                          },
                          [
                            _c("el-checkbox", { attrs: { label: index } }, [
                              _vm._v("正确答案"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.formData.itemList.length > 2 && _vm.userTime === 0
                      ? [
                          _c("span", [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              on: {
                                click: function ($event) {
                                  return _vm.delOption(item, index)
                                },
                              },
                            }),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              _c(
                "el-form-item",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        disabled: _vm.userTime !== 0,
                      },
                      on: { click: _vm.addOption },
                    },
                    [_vm._v("新增选项")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "解析：" } },
                [
                  _vm.show
                    ? _c("Tinymce", {
                        attrs: { width: "100%", height: 350 },
                        model: {
                          value: _vm.formData.analysis,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "analysis", $$v)
                          },
                          expression: "formData.analysis",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveCategory },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }