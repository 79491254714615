import $axios from '@/utils/request'

// 获取试题列表
export function getQuestionInfo (data) {
  const url = '/question/list'
  return $axios.fPost(url, data)
}

// 获取试题详细信息
export function questionGetInfo (data) {
  const url = '/question/getInfo'
  return $axios.fPost(url, data)
}

// 新增试题 -> (单选、多选、简答)
export function addQuestion (data) {
  const url = '/question/add'
  return $axios.fPost(url, data)
}

// 编辑试题/修改试题状态
export function editQuestion (data) {
  const url = '/question/edit'
  return $axios.fPost(url, data)
}

// 删除试题
export function delQuestion (data) {
  const url = '/question/del'
  return $axios.fPost(url, data)
}

// 获取试题使用次数
export function getQuesUsedTimes (data) {
  const url = '/question/getUsedTimes'
  return $axios.fGet(url, data)
}

// 重新判题
export function reJudge (data) {
  const url = '/question/reJudge'
  return $axios.fGet(url, data)
}