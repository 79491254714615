<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    width="60%"
    top="7vh"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="subjectTitle"></span>
    </div>
    <el-row>
      <!-- oldData：{{oldData.correctAnswer}}
      <hr>
      answerList：{{answerList}} -->
      <!-- answerList：{{answerList}}
      <hr>
      formData.stem：{{formData.stem}}
      <hr>
      correctAnswer：{{formData.correctAnswer}} -->
      <!-- userTime：{{userTime}} -->
      <!-- formData.tagSet：{{formData.tagSet}} -->
      <el-form ref="formData" :model="formData" :rules="rules" label-width="120px" style="width: 98%;">
        <el-form-item label="标签：">
          <el-cascader
            v-model="formData.tagSet"
            :options="tagTreeData"
            :props="props"
            placeholder="请选择所属标签"
            clearable></el-cascader>
        </el-form-item>
        <!-- <el-form-item label="题目名称：" prop="title">
          <el-input v-model="formData.title" placeholder="请填写题目名称"></el-input>
        </el-form-item> -->
        <el-form-item label="题干：" prop="stem">
          <Tinymce v-model="formData.stem" width="100%" :height="350" v-if="show" ref="filling" />
        </el-form-item>
        <el-form-item>
          <el-button @click="addFilling" type="info" :disabled="userTime !== 0">添加填空符</el-button>
        </el-form-item>
        <!-- 正确答案 -->

        <div v-for="(item, index) in answerList" :key="index">
          <!-- :prop="'answerList.' + index + '.value'"
          :rules="rules.value" -->
          <el-form-item
            :label="'正确答案：'+ (index +1) + '、'"
          >
            <el-row :gutter="20">
              <el-col :span="18">
                <div>
                  <!-- item.value：{{item.value}} -->
                  <el-select v-model="item.value" multiple filterable placeholder="请选择" style="width: 100%" @keyup.enter.native="submitForm(index, item.value)" :filter-method="dataFilter">
                    <el-option
                      v-for="(item, index) in item.itemAnswer"
                      :key="index"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div>
                  <span v-if="userTime === 0">
                    <i class="el-icon-delete" @click="delOption(item, index)"></i>
                  </span>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
        </div>
        <!-- 解析 -->
        <el-form-item label="解析：">
          <Tinymce v-model="formData.analysis" width="100%" :height="350" v-if="show" />
        </el-form-item>
      </el-form>
    </el-row>

    <el-row id="sourceDom" style="display: none"></el-row>

    <el-row slot="footer">
      <el-button type="primary" @click="saveCategory" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
// import Tinymce from '@/components/newTinymce'
import Tinymce from '@/components/newTinymce'
import { questionGetInfo, editQuestion, addQuestion, getQuesUsedTimes, reJudge } from '@/api/research/testquestion'
import getTag from '@/mixins/getTag'

export default {
  components: {
    Tinymce
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editTopicData: {
      default: null,
      type: Object
    },
    ExamDialog: { // 从试卷进来编辑更新试卷信息
      default: false,
      type: Boolean
    }
  },
  mixins: [getTag],
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    },
    formData :{
      handler (newVal) {
        if (this.editTopicData !== null && this.userTime !== 0) {
          return false
        } else {
          let userStr = newVal.stem
          let userBlank = userStr.match(/____/g)
          if (!userBlank) {
            this.answerList = []
          } else {
            this.getFillingArr(userBlank)
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('内容不能为空'))
      } else {
        callback()
      }
    }
    let RichVal = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('内容不能为空'))
      } else {
        callback()
      }
    }
    return {
      subjectTitle: '',
      oldData: {},
      formData: {
        title: '',
        stem: '',
        correctAnswer: '', // 参考答案
        analysis: ''
      },
      props: {
        multiple: true,
        value: 'id',
        label: 'title'
      },
      rules: {
        title: [{ required: true, message: '请输入题目名称', trigger: 'blur' }],
        stem: [{ required: true, validator: RichTextVal, trigger: 'blur' }],
        value: [{ required: true, validator: RichVal, trigger: 'blur' }],
      },
      answerList: [
        {
          value: '',
          itemAnswer: []
        }
      ],
      presentVal: '', // 用户正在填充的 val
      userTime: 0
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        title: '',
        stem: '',
        correctAnswer: '', // 参考答案
        analysis: ''
      }
      this.userTime = 0
      this.answerList = []
      if (this.editTopicData !== null) {
        this.subjectTitle = '编辑填空题'
        this.questionGetInfo(this.editTopicData.id)
        this.getQuesUsedTimes(this.editTopicData.id)
      } else {
        this.subjectTitle = '新增填空题'
      }
    },

    // 保存
    saveCategory () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (this.answerList.length === 0) {
            window.$msg('页面提示至少添加一个填空', 2)
            return false
          }
          let isNull = 0
          this.answerList.forEach((ele, idx) => {
            if (ele.value.length === 0) {
              isNull ++
            }
          })
          if (isNull) {
            window.$msg('正确答案不能为空', 2)
            return false
          }
          if (this.editTopicData !== null) {
            if (!this.formData.tagSet) {
              const correctAnswer = []
              this.answerList.forEach((ele, idx) => {
                correctAnswer.push(ele.value)
              })
              let editTopicData = {
                id: this.formData.id,
                title: this.formData.title,
                stem: this.formData.stem,
                correctAnswer: JSON.stringify(correctAnswer),
                analysis: this.formData.analysis,
                type: '填空'
              }
              editQuestion({
                ...editTopicData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('编辑成功')
                  this.close()
                  if (this.userTime && JSON.stringify(correctAnswer) !== this.oldData.correctAnswer) {
                    this.reJudge(this.editTopicData.id)
                  }
                  if (!this.ExamDialog) {
                    this.$emit('getQuestionInfo')
                  } else {
                    this.$emit('getExamInfo')
                  }
                }
              })
            } else {
              const tagSet = []
              const tagSetArr = this.formData.tagSet
              tagSetArr.forEach((ele, idx) => {
                tagSet.push(
                  ele[ele.length-1]
                )
              })
              const correctAnswer = []
              this.answerList.forEach((ele, idx) => {
                correctAnswer.push(ele.value)
              })
              let editTopicData = {
                id: this.formData.id,
                title: this.formData.title,
                stem: this.formData.stem,
                correctAnswer: JSON.stringify(correctAnswer),
                analysis: this.formData.analysis,
                tagSet: tagSet,
                type: '填空'
              }
              editQuestion({
                ...editTopicData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('编辑成功')
                  this.close()
                  if (this.userTime && JSON.stringify(correctAnswer) !== this.oldData.correctAnswer) {
                    this.reJudge(this.editTopicData.id)
                  }
                  if (!this.ExamDialog) {
                    this.$emit('getQuestionInfo')
                  } else {
                    this.$emit('getExamInfo')
                  }
                }
              })
            }

          } else {
            if (!this.formData.tagSet) {
              const correctAnswer = []
              this.answerList.forEach((ele, idx) => {
                correctAnswer.push(ele.value)
              })
              let addData = {
                title: this.formData.title,
                stem: this.formData.stem,
                analysis: this.formData.analysis,
                correctAnswer: JSON.stringify(correctAnswer),
                status: '已发布',
                type: '填空'
              }
              addQuestion({
                ...addData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('添加成功')
                  this.close()
                  this.$emit('getQuestionInfo')
                }
              })
            } else {
              const tagSet = []
              const tagSetArr = this.formData.tagSet
              tagSetArr.forEach((ele, idx) => {
                tagSet.push(
                  ele[ele.length-1]
                )
              })
              const correctAnswer = []
              this.answerList.forEach((ele, idx) => {
                correctAnswer.push(ele.value)
              })
              let addData = {
                title: this.formData.title,
                stem: this.formData.stem,
                analysis: this.formData.analysis,
                correctAnswer: JSON.stringify(correctAnswer),
                tagSet: tagSet,
                status: '已发布',
                type: '填空'
              }
              addQuestion({
                ...addData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('添加成功')
                  this.close()
                  this.$emit('getQuestionInfo')
                }
              })
            }
          }
        }
      })
    },

    // 获取试题使用次数
    getQuesUsedTimes (questionId) {
      getQuesUsedTimes({
        questionId
      }).then(res => {
        if (res.state === 'success') {
          this.userTime = res.body
        }
      })
    },

    // 重新判题
    reJudge (id) {
      reJudge({
        questionId: id
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('试题重判成功')
        }
      })
    },

    // 检测用户输入，获取填空符号
    getFillingArr (arr) {
      if (arr.length === this.answerList.length) {
        return false
      } else {
        if (Number(arr.length) > Number(this.answerList.length)) {
          this.answerList.push({
            value: '',
            itemAnswer: []
          })
          for (let i = 0; i < (Number(arr.length) - Number(this.answerList.length)); i++) {
            this.answerList.push({
              value: '',
              itemAnswer: []
            })
          }
        } else {
          for (let i = 0; i < (Number(this.answerList.length) - Number(arr.length)); i++) {
            this.answerList.pop()
          }
        }
        return false
      }
    },

    submitForm (index) {
      this.answerList[index].itemAnswer.push(this.presentVal)
    },

    // 获取试题详细信息
    async questionGetInfo (id) {
      const res = await questionGetInfo({
        id
      })
      this.formData = Object.assign({}, res.body)
      const correctAnswer = JSON.parse(this.formData.correctAnswer)
      correctAnswer.forEach((ele, idx) => {
        this.answerList.push({
          value: ele,
          itemAnswer: ele
        })
      })

      const tagGroupOrderList = this.tagGroupList
      const tagList = this.formData.tagList
      const tagSet = []
      tagGroupOrderList.forEach((item, index) => {
        tagList.forEach((ele, idx) => {
          if (item.id === ele.groupId) {
            tagSet.push([
              ele.groupId, ele.id
            ])
          }
        })
      })
      this.formData.tagSet = tagSet

      this.oldData = JSON.parse(JSON.stringify(this.formData))
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 添加填空符
    addFilling () {
      if (!this.formData.stem || this.formData.stem === '<p><br></p>') {
        this.formData.stem = '<p>____</p>'
      } else {
        const stem = this.formData.stem
        let stemP = stem.split('</p>')
        let newStem = ''
        stemP.pop() // 删除末尾垃圾数据
        stemP.forEach((ele, idx) => {
          if (idx === stemP.length - 1) {
            newStem += ele + '____</p>'
          } else {
            newStem += ele + '</p>'
          }
        })
        this.formData.stem = newStem
      }
      this.$refs.filling.setContent(this.formData.stem)
    },

    // 删除填空符
    delOption (item, index) {
      const stem = this.formData.stem
      let stemP = stem.split('____')
      let newStem = ''
      stemP.forEach((ele, idx) => {
        if (idx >= 1) {
          if (idx === (index + 1)) {
            newStem += ele
          } else {
            newStem += '____' + ele
          }
        } else {
          newStem += ele
        }
      })
      this.answerList.splice(index, 1)
      this.formData.stem = newStem
    },

    dataFilter (val) {
      this.presentVal = val
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-dialog {
  min-width: 940px;
}

/deep/ .el-dialog__body {
  padding-right: 90px !important;
}

.el-icon-delete {
  cursor: pointer;
}
</style>
