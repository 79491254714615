var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        width: "60%",
        top: "7vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.subjectTitle) } }),
        ]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticStyle: { width: "98%" },
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签：" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.tagTreeData,
                      props: _vm.props,
                      placeholder: "请选择所属标签",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.tagSet,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "tagSet", $$v)
                      },
                      expression: "formData.tagSet",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "题干：", prop: "stem" } },
                [
                  _vm.show
                    ? _c("Tinymce", {
                        ref: "filling",
                        attrs: { width: "100%", height: 350 },
                        model: {
                          value: _vm.formData.stem,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "stem", $$v)
                          },
                          expression: "formData.stem",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info", disabled: _vm.userTime !== 0 },
                      on: { click: _vm.addFilling },
                    },
                    [_vm._v("添加填空符")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.answerList, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "正确答案：" + (index + 1) + "、" } },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", { attrs: { span: 18 } }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        multiple: "",
                                        filterable: "",
                                        placeholder: "请选择",
                                        "filter-method": _vm.dataFilter,
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.submitForm(
                                            index,
                                            item.value
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    },
                                    _vm._l(
                                      item.itemAnswer,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: { label: item, value: item },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _c("div", [
                                _vm.userTime === 0
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delOption(item, index)
                                          },
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "el-form-item",
                { attrs: { label: "解析：" } },
                [
                  _vm.show
                    ? _c("Tinymce", {
                        attrs: { width: "100%", height: 350 },
                        model: {
                          value: _vm.formData.analysis,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "analysis", $$v)
                          },
                          expression: "formData.analysis",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("el-row", {
        staticStyle: { display: "none" },
        attrs: { id: "sourceDom" },
      }),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveCategory },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }