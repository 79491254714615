<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    width="60%"
    top="7vh"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="subjectTitle"></span>
    </div>
    <!-- formData.tagSet： {{formData.tagSet}} -->
    <el-row>
      <el-form ref="formData" :model="formData" :rules="rules" label-width="120px" style="width: 98%;">
        <el-form-item label="标签：">
          <el-cascader
            v-model="formData.tagSet"
            :options="tagTreeData"
            :props="props"
            placeholder="请选择所属标签"
            clearable></el-cascader>
        </el-form-item>
        <!-- <el-form-item label="题目名称：" prop="title">
          <el-input v-model="formData.title" placeholder="请填写题目名称"></el-input>
        </el-form-item> -->
        <el-form-item label="题干：" prop="stem">
          <Tinymce v-model="formData.stem" width="100%" :height="350" v-if="show" />
        </el-form-item>

        <!-- 参考答案 -->
        <el-form-item label="参考答案:">
          <Tinymce v-model="formData.correctAnswer" width="100%" :height="350" v-if="show" />
        </el-form-item>
        <!-- 解析 -->
        <el-form-item label="解析：">
          <Tinymce v-model="formData.analysis" width="100%" :height="350" v-if="show" />
        </el-form-item>
      </el-form>
    </el-row>

    <el-row slot="footer">
      <el-button type="primary" @click="saveCategory" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import { questionGetInfo, editQuestion, addQuestion } from '@/api/research/testquestion'
import getTag from '@/mixins/getTag'

export default {
  components: {
    Tinymce
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editTopicData: {
      default: null,
      type: Object
    },
    ExamDialog: { // 从试卷进来编辑更新试卷信息
      default: false,
      type: Boolean
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  mixins: [getTag],
  data () {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('内容不能为空'))
      } else {
        callback()
      }
    }
    return {
      subjectTitle: '',
      formData: {
        title: '',
        stem: '',
        correctAnswer: '', // 参考答案
        analysis: ''
      },
      props: {
        multiple: true,
        value: 'id',
        label: 'title'
      },
      rules: {
        title: [{ required: true, message: '请输入题目名称', trigger: 'blur' }],
        stem: [{ required: true, validator: RichTextVal, trigger: 'blur' }]
      }
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        title: '',
        stem: '',
        correctAnswer: '', // 参考答案
        analysis: ''
      }
      if (this.editTopicData !== null) {
        this.subjectTitle = '编辑简答题'
        this.questionGetInfo(this.editTopicData.id)
      } else {
        this.subjectTitle = '新增简答题'
      }
    },

    // 保存
    saveCategory () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (this.editTopicData !== null) {
            if (!this.formData.tagSet) {
              let editTopicData = {
                id: this.formData.id,
                title: this.formData.title,
                stem: this.formData.stem,
                correctAnswer: this.formData.correctAnswer,
                analysis: this.formData.analysis,
                type: '简答'
              }
              editQuestion({
                ...editTopicData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('编辑成功')
                  this.close()
                  if (!this.ExamDialog) {
                    this.$emit('getQuestionInfo')
                  } else {
                    this.$emit('getExamInfo')
                  }
                }
              })
            } else {
              const tagSet = []
              const tagSetArr = this.formData.tagSet
              tagSetArr.forEach((ele, idx) => {
                tagSet.push(
                  ele[ele.length-1]
                )
              })
              let editTopicData = {
                id: this.formData.id,
                title: this.formData.title,
                stem: this.formData.stem,
                correctAnswer: this.formData.correctAnswer,
                analysis: this.formData.analysis,
                tagSet: tagSet,
                type: '简答'
              }
              editQuestion({
                ...editTopicData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('编辑成功')
                  this.close()
                  if (!this.ExamDialog) {
                    this.$emit('getQuestionInfo')
                  } else {
                    this.$emit('getExamInfo')
                  }
                }
              })
            }
          } else {
            if (!this.formData.tagSet) {
              let addData = {
                title: this.formData.title,
                stem: this.formData.stem,
                analysis: this.formData.analysis,
                correctAnswer: this.formData.correctAnswer,
                type: '简答',
                status: '已发布'
              }
              addQuestion({
                ...addData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('添加成功')
                  this.close()
                  this.$emit('getQuestionInfo')
                }
              })
            } else {
              const tagSet = []
              const tagSetArr = this.formData.tagSet
              tagSetArr.forEach((ele, idx) => {
                tagSet.push(
                  ele[ele.length-1]
                )
              })
              let addData = {
                title: this.formData.title,
                stem: this.formData.stem,
                analysis: this.formData.analysis,
                correctAnswer: this.formData.correctAnswer,
                tagSet: tagSet,
                type: '简答',
                status: '已发布',
              }
              addQuestion({
                ...addData
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('添加成功')
                  this.close()
                  this.$emit('getQuestionInfo')
                }
              })
            }
          }
        }
      })
    },

    // 获取试题详细信息
    async questionGetInfo (id) {
      const res = await questionGetInfo({
        id
      })
      this.formData = Object.assign({}, res.body)

      const tagGroupOrderList = this.tagGroupList
      const tagList = this.formData.tagList
      const tagSet = []
      tagGroupOrderList.forEach((item, index) => {
        tagList.forEach((ele, idx) => {
          if (item.id === ele.groupId) {
            tagSet.push([
              ele.groupId, ele.id
            ])
          }
        })
      })
      this.formData.tagSet = tagSet
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-dialog {
  min-width: 940px;
}
</style>
